export const PASSWORD_VALIDATION = {
    minLength: (value: string) => value.length >= 8 || "Passwords must be at least 8 characters",
    maxLength: (value: string) => value.length < 16 || "Passwords must be at most 16 characters",
    uppercaseLetter: (value: string) =>
        /[A-Z]/.test(value) || "Password must contain one uppercase letter",
    lowercaseLetter: (value: string) =>
        /[a-z]/.test(value) || "Password must contain one lowercase letter",
    hasNumber: (value: string) => /[0-9]/.test(value) || "Password must contain one number",
    hasSymbol: (value: string) =>
        /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/.test(value) || "Password must contain one symbol"
};

export const EMAIL_REGEX =
    /^(?!.*[а-яА-ЯЁё])[a-zA-Z0-9]([a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/i;
