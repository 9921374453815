import { useSearchParams } from "react-router-dom";
import PageWrapper from "../../components/common/PageWrapper";
import CheckEmailForm from "../../components/forms/CheckEmailForm";
import NewPasswordView from "./NewPasswordView";
import { Label } from "./styles";

function ResetPassword() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    if (token) {
        return <NewPasswordView token={token} />;
    }

    return (
        <PageWrapper title="Forgot Password?">
            <Label>No worries, we'll send you reset instruction.</Label>
            <CheckEmailForm />
        </PageWrapper>
    );
}

export default ResetPassword;
