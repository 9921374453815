import type { AxiosResponse } from "axios";
import instance from "../api/instance";
import { HttpRoutes } from "../api/routes";
import type { UserResponse } from "../types";
import { TokenLocalHandler } from "../helpers/handlers";

export default class LoginService {
    static async getUser(): Promise<AxiosResponse<UserResponse>> {
        return instance.get(HttpRoutes.USER);
    }

    static async login(email: string, password: string): Promise<UserResponse["client"]> {
        try {
            const { data } = await instance.post<UserResponse>(HttpRoutes.LOGIN, {
                email,
                password
            });
            TokenLocalHandler.set(data.access_token);
            return data.client;
        } catch (error) {
            throw error;
        }
    }

    static async verifyEmail(email: string): Promise<AxiosResponse<{ message: string }>> {
        return instance.post(HttpRoutes.FORGET_PASSWORD, { email });
    }

    static async resetPassword(
        token: string,
        password: string,
        password_confirmation: string
    ): Promise<AxiosResponse<{ message: string }>> {
        return instance.post(HttpRoutes.RESET_PASSWORD, {
            token,
            password,
            password_confirmation
        });
    }
}
