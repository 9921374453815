import { ChangeEvent, FC, InputHTMLAttributes, ReactNode } from "react";
import { useController, Control } from "react-hook-form";
import styled from "styled-components";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    control: Control<any>;
    label: ReactNode;
}

const Checkbox: FC<CheckboxProps> = ({ name, control, label, ...props }) => {
    const {
        field: { value, onChange }
    } = useController({
        name,
        control,
        defaultValue: false
    });

    return (
        <Label>
            <Input
                {...props}
                type="checkbox"
                checked={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
            />
            <StyledCheckbox checked={value}>
                {value && (
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="16" height="16" rx="4" fill="black" />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.49982 10.0849L4.76482 8.34991C4.56982 8.15491 4.25482 8.15491 4.05982 8.34991C3.86482 8.54491 3.86482 8.85991 4.05982 9.05491L6.14982 11.1449C6.34482 11.3399 6.65982 11.3399 6.85482 11.1449L12.1448 5.85491C12.3398 5.65991 12.3398 5.34491 12.1448 5.14991C11.9498 4.95491 11.6348 4.95491 11.4398 5.14991L6.49982 10.0849Z"
                            fill="white"
                        />
                    </svg>
                )}
            </StyledCheckbox>
            <LabelText>{label}</LabelText>
        </Label>
    );
};

const Label = styled.label`
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    user-select: none;
    margin-bottom: 12px;
`;

const Input = styled.input`
    display: none;
`;

const StyledCheckbox = styled.span<{ checked: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #aaa;
    margin-right: 8px;
    background-color: ${(props) => (props.checked ? "black" : "transparent")};
    border-color: ${(props) => (props.checked ? "black" : "#aaa")};
    transition: background-color 0.3s, border-color 0.3s;

    &:hover {
        border-color: #555;
    }
`;

const LabelText = styled.span`
    &,
    * {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: rgba(0, 0, 0, 0.5);
        transition: all 0.2s ease-in-out;
    }

    a:hover {
        color: var(--font-color-black);
    }
`;

export default Checkbox;
