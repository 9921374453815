import PageWrapper from "../../components/common/PageWrapper";
import LoginForm from "../../components/forms/LoginForm";

function Login() {
    return (
        <PageWrapper title="Login">
            <LoginForm />
        </PageWrapper>
    );
}

export default Login;
