import type { AxiosResponse } from "axios";
import type { UserResponse, VerificationFormInputs } from "../types";
import { HttpRoutes } from "../api/routes";
import instance from "../api/instance";

export default class VerificationService {
    static async verifyUser(input: VerificationFormInputs): Promise<AxiosResponse<UserResponse>> {
        const { agreement, address_confirmation, id_image, selfie_with_id, ...rest } = input;

        const formData = new FormData();
        const documents = [
            { type: "address_confirmation", file: address_confirmation },
            { type: "id_image", file: id_image },
            { type: "selfie_with_id", file: selfie_with_id }
        ];

        Object.entries(rest).forEach(([key, value]) => {
            formData.append(key, value);
        });

        documents.forEach((document, index) => {
            if (document.file) {
                formData.append(`documents[${index}][type]`, document.type);
                formData.append(`documents[${index}][file]`, document.file);
            }
        });

        return instance.post(HttpRoutes.VERIFY_USER, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
}
