import styled from "styled-components";

export const PageSubTitle = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-medium);
    line-height: 100%;
    color: var(--font-color-black);
    opacity: 0.5;
`;

export const SecurityFormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 350px;
    margin: 25px 0 12px 0;

    .confirmation-content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`;
