import type { FC } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import type { LoginFormInputs } from "../../types";
import { Button } from "../common/Button";
import { Input } from "../common/Input";
import { PasswordInput } from "../common/PasswordInput";
import { RoutesNames } from "../../routes";
import { EMAIL_REGEX, PASSWORD_VALIDATION } from "../../helpers/validations";
import { checkUserVerification } from "../../helpers/redirects";
import { errorHandler } from "../../helpers/handlers";
import LoginService from "../../services/login-service";
import { useAuth } from "../../containers/AuthContextProvider";

const LoginForm: FC = () => {
    const navigate = useNavigate();
    const { setUser } = useAuth();
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<LoginFormInputs>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            email: "",
            password: ""
        }
    });

    const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
        try {
            const user = await LoginService.login(data.email, data.password);
            setUser(user);
            checkUserVerification(user, navigate);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Input
                    label="Email"
                    error={errors.email?.message}
                    type="email"
                    placeholder="Email"
                    id="email"
                    {...register("email", {
                        required: "Email is required",
                        pattern: {
                            value: EMAIL_REGEX,
                            message: "Please enter a valid email address"
                        },
                        validate: {
                            spacesOnly: (value) => !!value.trim() || "Required field"
                        }
                    })}
                />
                <PasswordInput
                    isReset
                    label="Password"
                    error={errors.password?.message}
                    placeholder="Password"
                    id="password"
                    {...register("password", {
                        required: "Password is required",
                        validate: PASSWORD_VALIDATION
                    })}
                />
                <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Logging in..." : "Next"}
                </Button>
            </form>
            <p>
                Don't have an account? <Link to={RoutesNames.REGISTRATION}>Create account</Link>
            </p>
        </Container>
    );
};

export default LoginForm;

const Container = styled.div`
    width: 424px;

    & > form {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    & > p {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;
        margin: 20px 0 12px 0;

        &,
        a {
            font-style: normal;
            font-weight: 500;
            font-size: var(--font-size-medium);
            line-height: 100%;
            color: rgba(0, 0, 0, 0.5);
            transition: color 0.2s ease-in-out;
        }

        a:hover {
            color: var(--font-color-black);
        }
    }

    @media (max-width: 530px) {
        width: 100%;
    }
`;
