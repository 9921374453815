import PageWrapper from "../../components/common/PageWrapper";
import VerificationForm from "../../components/forms/VerificationForm";

function Information() {
    return (
        <PageWrapper title="Verification">
            <VerificationForm />
        </PageWrapper>
    );
}

export default Information;
