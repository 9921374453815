import { ChangeEvent, FC, ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import { Control, useController } from "react-hook-form";
import styled from "styled-components";
import { formatBytes } from "../../helpers/files-helpers";

interface SuccessComponentProps {
    file: File;
    remove: () => void;
}

const SuccessUpload: FC<SuccessComponentProps> = ({ file, remove }) => {
    return (
        <Success>
            <div className="result-info">
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6.99984 12.8334C3.77964 12.8299 1.17004 10.2203 1.1665 7.00009V6.88343C1.23063 3.67774 3.87001 1.12473 7.07606 1.16727C10.2821 1.20981 12.8528 3.83194 12.8319 7.03821C12.8109 10.2445 10.2062 12.8328 6.99984 12.8334ZM4.32234 6.76093L3.49984 7.58343L5.83317 9.91676L10.4998 5.25009L9.67734 4.42176L5.83317 8.26593L4.32234 6.76093Z"
                        fill="#4ABA8C"
                    />
                </svg>
                <div className="file">
                    <span className="file-name">{file.name}</span>
                    <span className="file-size">{formatBytes(file.size)}</span>
                </div>
            </div>
            <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={remove}
            >
                <g opacity="0.5">
                    <path
                        d="M10.2502 12.8334H4.41683C3.7725 12.8334 3.25016 12.3111 3.25016 11.6667V4.08342H2.0835V2.91675H4.41683V2.33341C4.41683 1.68908 4.93916 1.16675 5.5835 1.16675H9.0835C9.72783 1.16675 10.2502 1.68908 10.2502 2.33341V2.91675H12.5835V4.08342H11.4168V11.6667C11.4168 12.3111 10.8945 12.8334 10.2502 12.8334ZM4.41683 4.08342V11.6667H10.2502V4.08342H4.41683ZM5.5835 2.33341V2.91675H9.0835V2.33341H5.5835ZM9.0835 10.5001H7.91683V5.25008H9.0835V10.5001ZM6.75016 10.5001H5.5835V5.25008H6.75016V10.5001Z"
                        fill="black"
                    />
                </g>
            </svg>
        </Success>
    );
};

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    name: string;
    formats: string[];
    control: Control<any>;
}

const UploadInput: FC<Props> = forwardRef(
    ({ label, formats, control, name, ...props }, ref: ForwardedRef<HTMLInputElement>) => {
        const {
            field: { value, onChange },
            fieldState: { error }
        } = useController({
            name,
            control,
            defaultValue: null,
            rules: { required: `This field is required` }
        });

        const upload = (e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            if (!file) return;
            onChange(file);
        };

        return (
            <Container>
                <span className="label">{label}</span>
                <span className="formats">({formats.join(",")})</span>
                {value ? (
                    <SuccessUpload file={value} remove={() => onChange(null)} />
                ) : (
                    <label htmlFor={props.id}>Upload file</label>
                )}
                <input ref={ref} type="file" onChange={upload} {...props} />
                {error && <p>{error.message}</p>}
            </Container>
        );
    }
);

export default UploadInput;

const Container = styled.div`
    position: relative;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 3px;
    max-width: 190px;

    .label {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-medium);
        line-height: 100%;
        color: var(--font-color-black);
    }

    .formats {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-small);
        line-height: 100%;
        color: var(--font-color-black);
        opacity: 0.5;
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-small);
        line-height: 100%;
        color: #fd5e5e;
        margin: 0;
    }

    label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 40px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-small);
        line-height: 100%;
        text-align: center;
        color: #0000007d;
        cursor: pointer;
    }

    input {
        display: none;
    }

    @media (max-width: 530px) {
        max-width: 100%;
        margin: 0;
    }
`;

const Success = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    gap: 10px;
    border: 1px solid #4aba8c;
    border-radius: var(--button-radius);

    & > svg {
        cursor: pointer;
    }

    .result-info {
        display: flex;
        flex: 1;
        align-items: center;
        gap: 6px;

        .file {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
            font-family: var(--font-family);
            max-width: 115px;
            overflow: hidden;

            &-name {
                max-width: 115px;
                font-style: normal;
                font-weight: 500;
                font-size: var(--font-size-small);
                line-height: 100%;
                text-align: center;
                color: var(--font-color-black);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            &-size {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 100%;
                text-align: center;
                color: var(--font-color-black);
                opacity: 0.5;
            }
        }
    }
`;
