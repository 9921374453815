export enum VerificationStatusEnum {
    DEFAULT = "unverified",
    PENDING = "pending",
    FAILED = "declined",
    VERIFIED = "verified"
}

export interface LoginFormInputs {
    email: string;
    password: string;
    agreement?: boolean;
}

export interface RegistrationFormInputs extends LoginFormInputs {
    phone: string;
    password_confirmation: string;
}

export interface InfoResource {
    name: string;
    surname: string;
    country: string;
    city: string;
    zip_code: string;
    address: string;
}

export interface VerificationFormInputs extends InfoResource {
    id_image: File | null;
    selfie_with_id: File | null;
    address_confirmation: File | null;
    agreement?: boolean;
}

export interface UserResponse {
    access_token: string;
    client: {
        email: string;
        phone: string;
        email_verified: boolean;
        phone_verified: boolean;
        verification_status: VerificationStatusEnum;
        info: InfoResource;
        wallet: { params: Array<string> } | null;
    };
}
