import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clsx } from "clsx";
import { toast } from "react-toastify";

import { RoutesNames } from "../../routes";
import { RegistrationFormInputs } from "../../types";
import { Button } from "../common/Button";
import Checkbox from "../common/Checkbox";
import { Input } from "../common/Input";
import { PasswordInput } from "../common/PasswordInput";
import { EMAIL_REGEX, PASSWORD_VALIDATION } from "../../helpers/validations";
import { useAuth } from "../../containers/AuthContextProvider";
import RegistrationService from "../../services/registration-service";
import { errorHandler } from "../../helpers/handlers";

function RegistrationForm() {
    const navigate = useNavigate();
    const { setUser } = useAuth();
    const {
        register,
        handleSubmit,
        control,
        getValues,
        formState: { errors, isSubmitting }
    } = useForm<RegistrationFormInputs>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            email: "",
            phone: "",
            password: "",
            password_confirmation: "",
            agreement: false
        }
    });

    const onSubmit: SubmitHandler<RegistrationFormInputs> = async (payload) => {
        if (!payload.agreement) {
            toast.error("Please read the Terms of Service and Privacy Policy");
            return;
        }

        const { agreement, ...rest } = payload;

        try {
            const user = await RegistrationService.registration(rest);
            setUser(user);
            navigate(RoutesNames.SECURITY);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Input
                    label="Email"
                    error={errors.email?.message}
                    type="email"
                    placeholder="Email"
                    id="email"
                    {...register("email", {
                        required: "Email is required",
                        pattern: {
                            value: EMAIL_REGEX,
                            message: "Please enter a valid email address"
                        },
                        validate: {
                            spacesOnly: (value) => !!value.trim() || "Required field"
                        }
                    })}
                />
                <Controller
                    name="phone"
                    control={control}
                    rules={{
                        required: true,
                        validate: (value) => isValidPhoneNumber(value)
                    }}
                    render={({ field, formState }) => {
                        const { onChange, value, name, ref } = field;
                        const isError = formState.errors[name];

                        return (
                            <PhoneInputContainer $error={!!isError}>
                                <span className="phone-label">Phone</span>
                                <PhoneInput
                                    ref={ref}
                                    className={clsx("phone_input", { error_input: isError })}
                                    placeholder="Phone"
                                    value={value ?? ""}
                                    onChange={onChange}
                                />
                                {isError && <p>Phone is required</p>}
                            </PhoneInputContainer>
                        );
                    }}
                />
                <div>
                    <PasswordInput
                        label="Password"
                        error={errors.password?.message}
                        placeholder="Password"
                        id="password"
                        {...register("password", {
                            validate: PASSWORD_VALIDATION
                        })}
                    />
                    <PasswordInput
                        label="Repeat Password"
                        error={errors.password_confirmation?.message}
                        placeholder="Repeat Password"
                        id="password_confirmation"
                        {...register("password_confirmation", {
                            validate: {
                                ...PASSWORD_VALIDATION,
                                matchesPreviousPassword: (value) => {
                                    const { password } = getValues();
                                    return password === value || "Passwords don't match";
                                }
                            }
                        })}
                    />
                </div>
                <Checkbox
                    name="agreement"
                    control={control}
                    label={
                        <span>
                            By creating an account, I agree to Falconic's 
                            <a href="/">Terms of Service</a> and <a href="/">Privacy Policy</a>.
                        </span>
                    }
                />
                <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Requesting..." : "Next"}
                </Button>
            </form>
            <p>
                Already have an account? <Link to={RoutesNames.LOGIN}>Log In</Link>
            </p>
        </Container>
    );
}

export default RegistrationForm;

const Container = styled.div`
    width: 424px;

    & > form {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    & > p {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;
        margin: 20px 0 12px 0;

        &,
        a {
            font-style: normal;
            font-weight: 500;
            font-size: var(--font-size-medium);
            line-height: 100%;
            color: rgba(0, 0, 0, 0.5);
        }

        a:hover {
            color: var(--font-color-black);
        }
    }

    @media (max-width: 530px) {
        width: auto;
    }
`;

const PhoneInputContainer = styled.div<{ $error: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .phone-label {
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-medium);
        line-height: 100%;
        color: var(--font-color-black);
    }

    input {
        width: 100%;
        padding: 0 16px;
        height: 44px;
        border: ${({ $error }) => ($error ? "var(--border-error)" : "var(--border-default)")};
        border-radius: var(--button-radius);
        align-self: stretch;
        transition: all 0.2s ease-in-out;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-medium);
        line-height: 100%;
        color: var(--font-color-black);

        &::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: var(--font-size-medium);
            line-height: 100%;
            color: var(--font-color-black);
            opacity: 0.5;
        }

        &:disabled {
            border: var(--border-default-disabled);
            background: #f0f0f0;
        }

        &:focus,
        &:focus-visible {
            border: ${({ $error }) => ($error ? "var(--border-error)" : "var(--border-default)")};
        }
    }

    & p {
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-small);
        line-height: 100%;
        color: #fd5e5e;
        margin: 0;
    }

    .phone_input > .PhoneInputCountry {
        display: none;
    }

    .PhoneInputInput {
        outline: none;
    }
`;
