import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";
import { useNavigate } from "react-router-dom";
import LoginService from "../services/login-service";
import type { UserResponse } from "../types";
import { checkUserVerification } from "../helpers/redirects";

interface IUserContext {
    user?: UserResponse["client"];
    loading: boolean;
    setUser: Dispatch<SetStateAction<UserResponse["client"] | undefined>>;
}

const AuthContext = createContext<IUserContext>({ loading: true, setUser: () => {} });

interface Props {
    children: ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
    const [user, setUser] = useState<UserResponse["client"]>();
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const checkVerification = async () => {
        try {
            const { data } = await LoginService.getUser();
            const client = data?.client;

            setUser(client);
            // check user data for redirection
            checkUserVerification(client, navigate);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!user) {
            // call request for checking user verification
            checkVerification();
        }
        return () => {
            setUser(undefined);
        };
    }, []);

    const value = {
        user,
        loading,
        setUser
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
