import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../components/common/Button";
import PageWrapper from "../../components/common/PageWrapper";
import { RoutesNames } from "../../routes";
import { VerificationStatusEnum } from "../../types";
import FailedVerification from "./FailedVerification";
import Information from "./Information";
import PendingVerification from "./PendingVerification";

function VerificationStatus() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const info = searchParams.get("info");
    const status = searchParams.get("status");

    if (info) {
        return <Information />;
    }

    if (status) {
        if (status === VerificationStatusEnum.PENDING) {
            return <PendingVerification />;
        }
        if (status === VerificationStatusEnum.FAILED) {
            return <FailedVerification />;
        }
    }

    return (
        <PageWrapper title="Verification">
            <Button
                variant="colored"
                onClick={() => navigate(`${RoutesNames.VERIFICATION}?info=true`)}
            >
                Start Verification
            </Button>
        </PageWrapper>
    );
}

export default VerificationStatus;
