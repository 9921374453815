import { Watch } from "react-loader-spinner";
import PageWrapper from "../../components/common/PageWrapper";
import { PendingPageWrapper } from "./styles";

function PendingVerification() {
    return (
        <PageWrapper>
            <PendingPageWrapper>
                <Watch visible height="100" width="100" radius="48" color="#6FEDB9" />
                <h3>Your Verification in Process</h3>
            </PendingPageWrapper>
        </PageWrapper>
    );
}

export default PendingVerification;
