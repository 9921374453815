import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useAuth } from "../containers/AuthContextProvider";
import { VerificationStatusEnum } from "../types";
import { Loader } from "./common/Loader";

function Layout({ children }: { children: ReactElement }) {
    const { loading } = useAuth();
    const { search } = useLocation();

    return (
        <LayoutContainer $failed={search.includes(VerificationStatusEnum.FAILED)}>
            <div className="bg"></div>
            <div className="bg bg2"></div>
            <div className="bg bg3"></div>
            {loading && <Loader />}
            {!loading && (
                <StyledContent $isverify={search.includes("info")}>{children}</StyledContent>
            )}
        </LayoutContainer>
    );
}

export default Layout;

const slide = keyframes`
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
`;

const LayoutContainer = styled.div<{ $failed: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    .bg {
        animation: ${slide} 3s ease-in-out infinite alternate;
        background-image: ${({ $failed }) =>
            $failed
                ? "linear-gradient(-60deg, rgb(235, 249, 201) 50%, #fa7676 50%)"
                : "linear-gradient(-60deg, rgb(224, 253, 155) 50%, rgb(111, 237, 185) 50%)"};

        bottom: 0;
        left: -50%;
        opacity: 0.5;
        position: fixed;
        right: -50%;
        top: 0;
        z-index: -1;
    }

    .bg2 {
        animation-direction: alternate-reverse;
        animation-duration: 4s;
    }

    .bg3 {
        animation-duration: 5s;
    }
`;

const StyledContent = styled.div<{ $isverify: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;

    @media (max-width: 530px) {
        .transition-element {
            width: 100%;
            ${({ $isverify }) => $isverify && "height: inherit"}
        }
    }
`;
