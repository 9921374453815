import { FC, ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import styled from "styled-components";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    error?: string;
}

export const Input: FC<Props> = forwardRef(
    ({ label, error, ...props }, ref: ForwardedRef<HTMLInputElement>) => {
        return (
            <Container $error={!!error}>
                <label htmlFor={props.id}>{label}</label>
                <input ref={ref} {...props} />
                {error && <p className="error">{error}</p>}
            </Container>
        );
    }
);

const Container = styled.div<{ $error: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 10px 0;

    & label {
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-medium);
        line-height: 100%;
        color: var(--font-color-black);
    }

    & input {
        padding: 0 16px;
        height: 44px;
        border: ${({ $error }) => ($error ? "var(--border-error)" : "var(--border-default)")};
        border-radius: 8px;
        align-self: stretch;
        flex-grow: 0;
        transition: all 0.2s ease-in-out;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-medium);
        line-height: 100%;
        color: var(--font-color-black);
        background-color: #fff !important;

        &::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: var(--font-size-medium);
            line-height: 100%;
            color: var(--font-color-black);
            opacity: 0.5;
        }

        &:disabled {
            border: var(--border-default-disabled);
            background: #f0f0f0;
        }

        &:focus,
        &:focus-visible {
            border: ${({ $error }) => ($error ? "var(--border-error)" : "var(--border-default)")};
        }
    }

    & p {
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-small);
        line-height: 100%;
        color: #fd5e5e;
        margin: 0;
    }

    @media (max-width: 530px) {
        margin: 0;
    }
`;
