import styled from "styled-components";
import { useCountdown } from "../../hooks/useCountdown";

export const ResendCodeComponent = ({ validate }: { validate: () => Promise<void> }) => {
    const { count, setCount } = useCountdown();

    const resend = async () => {
        setCount(45);
        await validate();
    };

    return (
        <Container $isdisabled={count > 0}>
            <button disabled={count > 0} onClick={resend}>
                Resend the code{" "}
            </button>
            {count > 0 && <span>after {count} seconds</span>}
        </Container>
    );
};

const Container = styled.div<{ $isdisabled: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-medium);
    line-height: 130%;
    color: var(--font-color-black);
    opacity: 0.5;
    margin-top: 8px;
    transition: opacity 0.2s ease-in-out;

    button {
        font-family: var(--font-family);
        font-weight: 500;
        font-size: var(--font-size-medium);
        line-height: 130%;
        border: none;
        outline: none;
        background: transparent;
        text-decoration-line: underline;
        cursor: pointer;
    }

    &:hover {
        opacity: ${({ $isdisabled }) => ($isdisabled ? 0.5 : 1)};
    }
`;
