import PageWrapper from "../../components/common/PageWrapper";
import ResetPasswordForm from "../../components/forms/ResetPasswordForm";
import { Label } from "./styles";

function NewPasswordView({ token }: { token: string }) {
    return (
        <PageWrapper title="Reset Password">
            <Label>No worries, we'll send you reset instruction.</Label>
            <ResetPasswordForm token={token} />
        </PageWrapper>
    );
}

export default NewPasswordView;
