import styled from "styled-components";

export const PendingPageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 320px;

    & > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & > h3 {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 600;
        font-size: var(--font-size-large);
        line-height: 100%;
        text-align: center;
        text-transform: uppercase;
        color: var(--font-color-black);
    }
`;

export const FailedPageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 190px;

    .status-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: var(--font-family);

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: var(--font-size-large);
            line-height: 100%;
            text-align: center;
            text-transform: uppercase;
            color: var(--font-color-red);
            margin: 0;
            margin-bottom: 8px;
        }

        span {
            font-style: normal;
            font-weight: 500;
            font-size: var(--font-size-medium);
            line-height: 100%;
            text-align: center;
            color: var(--font-color-black);
            opacity: 0.5;
        }
    }
`;
