import PageWrapper from "../../components/common/PageWrapper";
import RegistrationForm from "../../components/forms/RegistrationForm";

function Registration() {
    return (
        <PageWrapper title="Registration">
            <RegistrationForm />
        </PageWrapper>
    );
}

export default Registration;
