import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PASSWORD_VALIDATION } from "../../helpers/validations";
import { Description } from "../../pages/reset/styles";
import { RoutesNames } from "../../routes";
import { Button } from "../common/Button";
import { PasswordInput } from "../common/PasswordInput";
import { errorHandler } from "../../helpers/handlers";
import LoginService from "../../services/login-service";
import { toast } from "react-toastify";

interface PasswordFormInput {
    password: string;
    confirm_password: string;
}

function ResetPasswordForm({ token }: { token: string }) {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors, isSubmitting }
    } = useForm<PasswordFormInput>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            password: "",
            confirm_password: ""
        }
    });

    const onSubmit: SubmitHandler<PasswordFormInput> = async (payload) => {
        try {
            const { data } = await LoginService.resetPassword(
                token,
                payload.password,
                payload.confirm_password
            );
            toast.success(data.message);
            setTimeout(() => {
                navigate(RoutesNames.LOGIN);
            }, 3000);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <PasswordInput
                error={errors.password?.message}
                placeholder="Enter your new password"
                id="password"
                {...register("password", {
                    validate: PASSWORD_VALIDATION
                })}
            />
            <PasswordInput
                error={errors.confirm_password?.message}
                placeholder="Repeat new password"
                id="confirm_password"
                {...register("confirm_password", {
                    validate: {
                        ...PASSWORD_VALIDATION,
                        matchesPreviousPassword: (value) => {
                            const { password } = getValues();
                            return password === value || "Passwords don't match";
                        }
                    }
                })}
            />
            <Description>
                To protect your wallet as much as possible, use a password with at least 8
                characters, one small letter, one capital letter, one digit, and one special
                character.
            </Description>
            <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Saving..." : "Next"}
            </Button>
            <Link to={RoutesNames.LOGIN}>Back to Login</Link>
        </Form>
    );
}

export default ResetPasswordForm;

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;

    a {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-medium);
        line-height: 100%;
        text-align: center;
        color: var(--font-color-black);
        opacity: 0.5;
        margin: 20px 0;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
`;
