import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import styled from "styled-components";

const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL;

type VariantButtonType = "colored" | "default";
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    variant?: VariantButtonType;
    isSupport?: boolean;
}

export const Button: FC<Props> = ({
    children,
    variant = "default",
    isSupport = false,
    ...props
}) => {
    if (isSupport) {
        return (
            <StyledLink href={SUPPORT_URL} target="_blank" rel="noreferrer">
                <SupportBtn {...props}>
                    <svg
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.651878 4.95405L13.0676 0.0598993C13.6439 -0.152938 14.1471 0.203619 13.9604 1.09446L13.9615 1.09336L11.8475 11.2755C11.6908 11.9974 11.2712 12.1729 10.6843 11.8328L7.46497 9.40716L5.91219 10.9365C5.7405 11.1121 5.59563 11.2602 5.26297 11.2602L5.49154 7.91072L11.458 2.40001C11.7176 2.16633 11.4 2.03467 11.0577 2.26726L3.68445 7.0133L0.505936 5.99958C-0.184065 5.77577 -0.199088 5.29415 0.651878 4.95405Z"
                            fill="black"
                        />
                    </svg>

                    {children}
                </SupportBtn>
            </StyledLink>
        );
    }

    return (
        <StyledBtn {...props} $variant={variant}>
            {children}
        </StyledBtn>
    );
};

const StyledBtn = styled.button<{
    $variant: VariantButtonType;
}>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 10px;
    gap: 10px;
    height: 43px;
    border-radius: var(--button-radius);
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--button-text-size);
    line-height: 100%;
    text-align: center;
    color: var(--button-text-color);
    background: ${({ $variant }) =>
        $variant === "colored"
            ? `var(--background-button-gradient)`
            : `var(--background-button-disabled)`};
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: translateY(-2px);
        background: var(--background-button-gradient);
    }

    &:disabled {
        cursor: no-drop;
        pointer-events: none;
        background: var(--background-button-disabled);
    }
`;

const SupportBtn = styled.button`
    width: 190px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 10px;
    gap: 10px;
    height: 43px;
    border-radius: var(--button-radius);
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--button-text-size);
    line-height: 100%;
    text-align: center;
    color: var(--button-text-color);
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:disabled {
        cursor: no-drop;
        pointer-events: none;
        background: var(--background-button-disabled);
    }

    &:hover {
        background: #000;
        color: #fff;
        transform: translateY(-2px);

        svg {
            path {
                fill: #fff;
            }
        }
    }
`;

const StyledLink = styled.a`
    text-decoration: none;
`;
