import { Button } from "../../components/common/Button";
import PageWrapper from "../../components/common/PageWrapper";
import { FailedPageWrapper } from "./styles";

function FailedVerification() {
    return (
        <PageWrapper>
            <FailedPageWrapper>
                <div className="status-info">
                    <h3>Your verification has been failed.</h3>
                    <span>Please contact our support team for more information.</span>
                </div>
                <Button isSupport>Support</Button>
            </FailedPageWrapper>
        </PageWrapper>
    );
}

export default FailedVerification;
