import type { ComponentType, FC } from "react";
import { Navigate } from "react-router-dom";
import { RoutesNames } from "../routes";
import { useAuth } from "./AuthContextProvider";

const withAuth = <P extends object>(Component: ComponentType<P>): FC<P> => {
    const AuthHOC: FC<P> = (props) => {
        const { user } = useAuth();

        if (!user) {
            return <Navigate to={RoutesNames.LOGIN} replace />;
        }

        return <Component {...props} />;
    };

    return AuthHOC;
};

export default withAuth;
