import type { NavigateFunction } from "react-router-dom";
import { VerificationStatusEnum, type UserResponse } from "../types";
import { RoutesNames } from "../routes";

const WALLET_URL = process.env.REACT_APP_WALLET_URL;

export const openWallet = () => {
    if (WALLET_URL && window) {
        window.location.replace(WALLET_URL);
    }
};

export const checkUserVerification = (user: UserResponse["client"], navigate: NavigateFunction) => {
    if (!user) {
        navigate(RoutesNames.LOGIN);
        return;
    }

    if (!user.email_verified || !user.phone_verified) {
        navigate(RoutesNames.SECURITY);
    } else if (user.verification_status === VerificationStatusEnum.PENDING) {
        navigate(`${RoutesNames.VERIFICATION}?status=${VerificationStatusEnum.PENDING}`);
    } else if (user.verification_status === VerificationStatusEnum.FAILED) {
        navigate(`${RoutesNames.VERIFICATION}?status=${VerificationStatusEnum.FAILED}`);
    } else if (user.verification_status === VerificationStatusEnum.VERIFIED) {
        openWallet();
    } else {
        navigate(RoutesNames.VERIFICATION);
    }
};
