export enum HttpRoutes {
    USER = "/user",
    LOGIN = "/login",
    REGISTER = "/register",
    FORGET_PASSWORD = "/forget-password",
    RESET_PASSWORD = "/reset-password",
    VERIFY_CREDENTIAL = "/verify/send-code",
    VERIFY_CODE = "/verify/check-code",
    VERIFY_USER = "/user/verify",
    SET_WALLET = "/user/set-wallet"
}
