import { FC, ForwardedRef, forwardRef, InputHTMLAttributes, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RoutesNames } from "../../routes";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: string;
    isReset?: boolean;
}

export const PasswordInput: FC<Props> = forwardRef(
    ({ label, error, isReset = false, ...props }, ref: ForwardedRef<HTMLInputElement>) => {
        const [visible, setVisible] = useState<boolean>(false);

        const toggleVisible = () => setVisible((prev) => !prev);

        return (
            <Container $error={!!error} $isreset={isReset}>
                {label && <label htmlFor={props.id}>{label}</label>}
                <div className="input-container">
                    <input ref={ref} type={visible ? "text" : "password"} {...props} />
                    <div className="eye-icon" onClick={toggleVisible}>
                        {visible ? (
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g opacity="0.5">
                                    <path
                                        d="M9.99984 15.8334C8.63307 15.8504 7.28042 15.5554 6.04484 14.9709C5.08708 14.5036 4.22705 13.8582 3.51067 13.0692C2.75186 12.2535 2.15439 11.3014 1.74984 10.2634L1.6665 10L1.754 9.73671C2.15885 8.69957 2.75503 7.74777 3.5115 6.93088C4.22762 6.14197 5.08737 5.4966 6.04484 5.02921C7.28043 4.4447 8.63307 4.14972 9.99984 4.16671C11.3666 4.14975 12.7192 4.44473 13.9548 5.02921C14.9126 5.49649 15.7727 6.14187 16.489 6.93088C17.2492 7.74551 17.8469 8.69785 18.2498 9.73671L18.3332 10L18.2457 10.2634C16.9383 13.6665 13.645 15.8912 9.99984 15.8334ZM9.99984 5.83338C7.16306 5.74448 4.55935 7.39596 3.43067 10C4.55917 12.6043 7.163 14.2558 9.99984 14.1667C12.8365 14.2554 15.4401 12.604 16.569 10C15.4418 7.39469 12.8371 5.74261 9.99984 5.83338ZM9.99984 12.5C8.79761 12.508 7.75764 11.6645 7.51731 10.4865C7.27697 9.30853 7.9034 8.12506 9.01266 7.66143C10.1219 7.19779 11.4042 7.58349 12.0736 8.58213C12.7431 9.58078 12.6126 10.9134 11.7623 11.7634C11.2968 12.2344 10.6621 12.4997 9.99984 12.5Z"
                                        fill="black"
                                    />
                                </g>
                            </svg>
                        ) : (
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g opacity="0.5">
                                    <path
                                        d="M16.6415 17.8209L13.8448 15.0242C12.6383 15.5736 11.3255 15.8504 9.99984 15.835C8.63307 15.852 7.28043 15.5571 6.04484 14.9725C5.08707 14.5052 4.22703 13.8599 3.51067 13.0709C2.75025 12.2558 2.15258 11.3028 1.74984 10.2634L1.6665 10.0017L1.754 9.73838C2.35635 8.20195 3.37005 6.86101 4.684 5.86254L2.49984 3.67838L3.67734 2.50171L17.8182 16.6425L16.6432 17.8209H16.6415ZM5.86317 7.04254C4.79811 7.78914 3.95705 8.8123 3.43067 10.0017C4.55935 12.6058 7.16306 14.2573 9.99984 14.1684C10.8747 14.1755 11.7445 14.0347 12.5723 13.7517L11.0723 12.2517C10.7385 12.4154 10.3717 12.5009 9.99984 12.5017C8.62272 12.4931 7.50848 11.3788 7.49984 10.0017C7.50024 9.62901 7.58574 9.26133 7.74984 8.92671L5.86317 7.04254ZM16.5432 13.01L15.3832 11.8509C15.8712 11.2991 16.2712 10.6754 16.569 10.0017C15.4418 7.39636 12.8371 5.74427 9.99984 5.83504C9.794 5.83504 9.58734 5.84254 9.38734 5.85671L7.9165 4.38421C8.60119 4.23774 9.29966 4.16538 9.99984 4.16838C11.3666 4.15141 12.7192 4.44639 13.9548 5.03088C14.9126 5.49815 15.7727 6.14354 16.489 6.93254C17.2491 7.74668 17.8467 8.69844 18.2498 9.73671L18.3332 10.0017L18.2457 10.265C17.8555 11.2801 17.2783 12.2129 16.544 13.015L16.5432 13.01Z"
                                        fill="black"
                                    />
                                </g>
                            </svg>
                        )}
                    </div>

                    {error && <p className="error">{error}</p>}
                    {isReset && <Link to={RoutesNames.RESET}>Forgot Password?</Link>}
                </div>
            </Container>
        );
    }
);

const Container = styled.div<{ $error: boolean; $isreset: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 10px 0;
    margin-bottom: 18px;

    & label {
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-medium);
        line-height: 100%;
        color: var(--font-color-black);
    }

    .input-container {
        position: relative;
        display: flex;
        flex-direction: column;

        .eye-icon {
            position: absolute;
            right: 10px;
            top: 13px;
            cursor: pointer;
        }

        & input {
            padding: 0 38px 0 16px;
            height: 44px;
            min-height: 44px;
            border: ${({ $error }) => ($error ? "var(--border-error)" : "var(--border-default)")};
            border-radius: 8px;
            align-self: stretch;
            flex-grow: 0;
            transition: all 0.2s ease-in-out;
            flex: 1;
            background-color: #fff !important;
            font-style: normal;
            font-weight: 500;
            font-size: var(--font-size-medium);
            line-height: 100%;
            color: var(--font-color-black);
            outline: none;

            &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: var(--font-size-medium);
                line-height: 100%;
                color: var(--font-color-black);
                opacity: 0.5;
            }

            &:disabled {
                border: var(--border-default-disabled);
                background: #f0f0f0;
            }

            &:focus {
                border: ${({ $error }) =>
                    $error ? "var(--border-error)" : "var(--border-default)"};
            }
        }

        & p {
            position: ${({ $isreset }) => ($isreset ? "absolute" : "")};
            left: 0;
            bottom: 4px;
            font-style: normal;
            font-weight: 500;
            font-size: var(--font-size-small);
            line-height: 100%;
            color: #fd5e5e;
            margin: ${({ $isreset }) => ($isreset ? "0" : "6px 0 0 0")};
        }

        a {
            font-style: normal;
            font-weight: 500;
            font-size: var(--font-size-medium);
            line-height: 100%;
            color: var(--font-color-black);
            opacity: 0.5;
            margin-top: 8px;
            margin-left: auto;
            transition: opacity 0.2s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }
    }
`;
