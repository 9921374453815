import { useSpring, animated } from "@react-spring/web";
import styled, { keyframes } from "styled-components";

const AnimatedLogo = () => {
    const firstPathSpring = useSpring({
        from: { opacity: 0, transform: "scale(0.8)" },
        to: { opacity: 1, transform: "scale(1)" },
        config: { tension: 120, friction: 54 },
        delay: 200
    });

    const secondPathSpring = useSpring({
        from: { opacity: 0, transform: "translateX(-10px)" },
        to: { opacity: 1, transform: "translateX(0)" },
        config: { tension: 420, friction: 54 },
        delay: 700
    });

    return (
        <StyledContainer>
            <svg
                width="28"
                height="30"
                viewBox="0 0 28 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <animated.path
                    style={firstPathSpring}
                    d="M1.7478 30.0001C0.804524 27.9181 -0.468901 24.1216 0.819347 20.2616C2.99967 13.7292 11.7775 9.24231 22.124 10.9582C18.8292 11.8674 8.93827 15.0641 3.89982 24.4215C2.86626 26.3401 2.18845 28.237 1.7478 29.9988V30.0001Z"
                    fill="#161616"
                />
                <animated.path
                    style={secondPathSpring}
                    d="M0 3.20159C1.95528 5.54434 4.01432 6.3185 4.98724 6.59952C7.06919 7.2021 8.91532 6.91972 11.004 6.59952C13.6102 6.20095 13.8298 5.69836 16.312 5.43625C17.9748 5.26061 19.2078 5.34573 21.0755 5.47543C22.5982 5.58082 24.6276 5.79699 27.0101 6.27256C25.8755 5.01607 24.784 4.28379 23.9795 3.84334C22.9311 3.27049 22.3692 3.19078 20.8882 2.43013C19.414 1.67218 19.0219 1.26281 18.2888 0.862889C14.6666 -1.11102 9.45703 0.827762 7.71062 1.49384C6.60699 1.91402 5.92648 2.29232 4.4388 2.66521C2.60749 3.12458 1.0403 3.20699 0 3.20294V3.20159Z"
                    fill="#161616"
                />
            </svg>
            <svg
                width="151"
                height="20"
                viewBox="0 0 151 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <StyledPath
                    $delay={0.7}
                    d="M144.52 19.1451C142.693 19.1451 141.093 18.7652 139.721 18.0054C138.357 17.2371 137.296 16.1606 136.538 14.776C135.788 13.383 135.414 11.7578 135.414 9.90043C135.414 8.55806 135.637 7.32543 136.083 6.20257C136.529 5.07125 137.161 4.09191 137.978 3.26453C138.794 2.43716 139.759 1.79552 140.87 1.33961C141.99 0.883713 143.211 0.655762 144.533 0.655762C145.838 0.655762 146.979 0.820393 147.956 1.14966C148.933 1.47048 149.775 1.9306 150.482 2.53002L149.219 4.48027C148.647 3.96527 147.981 3.58113 147.223 3.32785C146.466 3.07457 145.628 2.94793 144.71 2.94793C143.674 2.94793 142.735 3.10834 141.893 3.42916C141.051 3.74998 140.331 4.21433 139.733 4.8222C139.144 5.42162 138.689 6.14769 138.369 7.00039C138.049 7.8531 137.889 8.81134 137.889 9.87511C137.889 11.3441 138.171 12.5979 138.735 13.6363C139.308 14.6747 140.116 15.4683 141.161 16.0171C142.205 16.5574 143.438 16.8276 144.861 16.8276C145.779 16.8276 146.659 16.6925 147.501 16.4224C148.352 16.1438 149.148 15.6921 149.889 15.0673L151 17.0935C150.133 17.7774 149.16 18.2924 148.082 18.6385C147.013 18.9763 145.826 19.1451 144.52 19.1451Z"
                    fill="#161616"
                />
                <StyledPath
                    $delay={0.6}
                    d="M125.341 18.7652V1.03564H127.716V18.7652H125.341Z"
                    fill="#161616"
                />
                <StyledPath
                    $delay={0.5}
                    d="M102.526 18.7652V1.03564H104.749L114.222 14.5861V1.03564H116.546V18.7652H114.323L104.863 5.06278V18.7652H102.526Z"
                    fill="#161616"
                />
                <StyledPath
                    $delay={0.4}
                    d="M85.5753 19.1451C84.2533 19.1451 83.0281 18.9129 81.8997 18.4486C80.7798 17.9758 79.7988 17.3215 78.9567 16.4857C78.1231 15.6499 77.4747 14.6705 77.0116 13.5476C76.5485 12.4248 76.3169 11.209 76.3169 9.90043C76.3169 8.58338 76.5485 7.36343 77.0116 6.24056C77.4747 5.11769 78.1231 4.13834 78.9567 3.30252C79.7988 2.4667 80.7798 1.81662 81.8997 1.35228C83.0281 0.887934 84.2533 0.655762 85.5753 0.655762C86.8889 0.655762 88.1057 0.892155 89.2256 1.36494C90.3456 1.83773 91.3266 2.49625 92.1686 3.34052C93.0107 4.18478 93.6633 5.16834 94.1264 6.29121C94.598 7.40564 94.8337 8.60871 94.8337 9.90043C94.8337 11.209 94.598 12.4248 94.1264 13.5476C93.6633 14.6705 93.0107 15.6499 92.1686 16.4857C91.3266 17.3215 90.3456 17.9758 89.2256 18.4486C88.1057 18.9129 86.8889 19.1451 85.5753 19.1451ZM85.5753 16.8783C86.5268 16.8783 87.411 16.7136 88.2278 16.3844C89.053 16.0551 89.773 15.5865 90.3877 14.9787C91.0024 14.3624 91.4824 13.6236 91.8276 12.7625C92.1813 11.9013 92.3581 10.9473 92.3581 9.90043C92.3581 8.49052 92.055 7.26211 91.4487 6.21523C90.8508 5.16834 90.0382 4.35785 89.0109 3.78375C87.9836 3.20121 86.8384 2.90994 85.5753 2.90994C84.6238 2.90994 83.7354 3.07879 82.9102 3.4165C82.0934 3.74576 81.3735 4.21855 80.7503 4.83486C80.1356 5.44273 79.6557 6.17724 79.3104 7.03838C78.9652 7.89109 78.7925 8.84511 78.7925 9.90043C78.7925 11.3019 79.0915 12.5261 79.6893 13.573C80.2956 14.6199 81.1124 15.4346 82.1397 16.0171C83.167 16.5912 84.3122 16.8783 85.5753 16.8783Z"
                    fill="#161616"
                />
                <StyledPath
                    $delay={0.3}
                    d="M64.0888 19.1451C62.2615 19.1451 60.6616 18.7652 59.2891 18.0054C57.9249 17.2371 56.8639 16.1606 56.1061 14.776C55.3566 13.383 54.9819 11.7578 54.9819 9.90043C54.9819 8.55806 55.2051 7.32543 55.6514 6.20257C56.0977 5.07125 56.7292 4.09191 57.546 3.26453C58.3628 2.43716 59.327 1.79552 60.4385 1.33961C61.5584 0.883713 62.7794 0.655762 64.1014 0.655762C65.4066 0.655762 66.5476 0.820393 67.5244 1.14966C68.5012 1.47048 69.3432 1.9306 70.0506 2.53002L68.7875 4.48027C68.2149 3.96527 67.5497 3.58113 66.7918 3.32785C66.0339 3.07457 65.1961 2.94793 64.2783 2.94793C63.2425 2.94793 62.3036 3.10834 61.4616 3.42916C60.6195 3.74998 59.8996 4.21433 59.3017 4.8222C58.7122 5.42162 58.2575 6.14769 57.9376 7.00039C57.6176 7.8531 57.4576 8.81134 57.4576 9.87511C57.4576 11.3441 57.7397 12.5979 58.3039 13.6363C58.8765 14.6747 59.6848 15.4683 60.729 16.0171C61.7731 16.5574 63.0067 16.8276 64.4298 16.8276C65.3477 16.8276 66.2276 16.6925 67.0697 16.4224C67.9202 16.1438 68.7159 15.6921 69.4569 15.0673L70.5684 17.0935C69.7011 17.7774 68.7285 18.2924 67.6507 18.6385C66.5813 18.9763 65.394 19.1451 64.0888 19.1451Z"
                    fill="#161616"
                />
                <StyledPath
                    $delay={0.2}
                    d="M39.2104 18.7652V1.03564H41.5851V16.5743H49.492V18.7652H39.2104Z"
                    fill="#161616"
                />
                <StyledPath
                    $delay={0.1}
                    d="M15.8784 18.7652L22.838 1.03564H25.3011L32.4754 18.7652H29.7976L28.0419 14.3201H20.1603L18.4804 18.7652H15.8784ZM20.8297 12.2432H27.3472L24.0127 3.98634L20.8297 12.2432Z"
                    fill="#161616"
                />
                <StyledPath
                    $delay={0}
                    d="M0.0922852 18.7652V1.03564H10.778V3.2265H2.46689V8.79864H10.0202V10.9388H2.46689V18.7652H0.0922852Z"
                    fill="#161616"
                />
            </svg>
        </StyledContainer>
    );
};

export default AnimatedLogo;

const appearAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
	50%{
		opacity: 0;
    transform: translateY(-5px);
	}
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 8px;
`;

const StyledPath = styled.path<{ $delay: number }>`
    opacity: 0;
    animation: ${appearAnimation} 0.5s forwards ease-out;
    animation-delay: ${({ $delay }) => $delay}s;
`;
