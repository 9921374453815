import { Button } from "../../components/common/Button";
import { VerificationBtn } from "../../components/common/VerificationBtn";
import PageWrapper from "../../components/common/PageWrapper";
import { PageSubTitle, SecurityFormContainer } from "./styles";
import { useNavigate } from "react-router-dom";
import { RoutesNames } from "../../routes";
import { useAuth } from "../../containers/AuthContextProvider";

function Security() {
    const navigate = useNavigate();
    const { user } = useAuth();

    return (
        <PageWrapper title="Security Verification Requirements">
            <PageSubTitle>
                You need to complete all of the following verifications to continue.
            </PageSubTitle>
            <SecurityFormContainer>
                <div className="confirmation-content">
                    <VerificationBtn
                        label="Email"
                        verifyBy="email"
                        field="email_verified"
                        defaultStatus={user?.email_verified ? "verified" : "default"}
                    />
                    <VerificationBtn
                        label="Phone Number"
                        verifyBy="phone"
                        field="phone_verified"
                        defaultStatus={user?.phone_verified ? "verified" : "default"}
                    />
                </div>
                <Button
                    disabled={!user?.email_verified || !user.phone_verified}
                    onClick={() => navigate(RoutesNames.VERIFICATION)}
                >
                    Next
                </Button>
            </SecurityFormContainer>
        </PageWrapper>
    );
}

export default Security;
