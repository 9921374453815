import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { RoutesNames } from "../routes";
import Login from "../pages/login";
import Registration from "../pages/registration";
import Verification from "../pages/verification-status";
import withAuth from "../containers/AuthHOK";
import ResetPassword from "../pages/reset";
import Layout from "./Layout";
import { animated, useTransition } from "react-spring";
import Security from "../pages/security";

// protected routes
const VerificationPage = withAuth(Verification);
const SecurityPage = withAuth(Security);

function App() {
    const location = useLocation();

    const transitions = useTransition(location, {
        from: { opacity: 0, transform: `translate3d(100%, 0, 0)` },
        enter: { opacity: 1, transform: `translate3d(0, 0, 0)` },
        leave: { opacity: 0, transform: `translate3d(-30%, 0, 0)` },
        config: { tension: 520, friction: 70 }
    });

    return (
        <Layout>
            {transitions((style, location) => (
                <animated.div
                    className="transition-element"
                    style={{ ...style, position: "absolute" }}
                >
                    <Routes location={location}>
                        <Route path={RoutesNames.LOGIN} element={<Login />} />
                        <Route path={RoutesNames.REGISTRATION} element={<Registration />} />
                        <Route path={RoutesNames.RESET} element={<ResetPassword />} />
                        <Route path={RoutesNames.SECURITY} element={<SecurityPage />} />
                        <Route path={RoutesNames.VERIFICATION} element={<VerificationPage />} />
                        <Route path="*" element={<Navigate to={RoutesNames.LOGIN} />} />
                    </Routes>
                </animated.div>
            ))}
        </Layout>
    );
}

export default App;
