import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";

// components
import App from "./components/App";
import { Loader } from "./components/common/Loader";
import { AuthContextProvider } from "./containers/AuthContextProvider";

// fonts, styles
import "./fonts/stylesheet.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";

const GlobalStyle = createGlobalStyle`
	:root {
		/* colors */
		--button-text-color: #000000;
		--font-color-secondary: #828282;
		--font-color-tertiary: #BDBDBD;
		--font-color-white: #FFFFFF;
		--font-color-black: #000000;
		--font-color-red: #f04a4a;
		--background-button-gradient: linear-gradient(99.9deg, #6fedb9 -0.49%, #e0fd9b 98.48%), rgba(0, 0, 0, 0.05);
		--background-button-disabled: rgba(0, 0, 0, 0.05);
		--background-color-white: #FFFFFF;
		/* sizes */
		--button-radius: 8px;
		--button-text-size: 16px;
		--font-size-small: 12px;
		--font-size-medium: 14px;
		--font-size-large: 16px;
		/* borders */
		--border-default: 2px solid #000000;
		--border-error: 2px solid #E35555;
		--border-default-disabled: 2px solid #00000055;

		--font-family: ${(props) => props.theme.fontFamily};
	}

  body {
    font-family: ${(props) => props.theme.fontFamily};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

	code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
	}

	.Toastify__toast{
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 14px;
	}
`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <BrowserRouter>
        <ThemeProvider theme={{ fontFamily: "Gilroy" }}>
            <Suspense fallback={<Loader />}>
                <AuthContextProvider>
                    <App />
                    <ToastContainer
                        theme="colored"
                        stacked
                        position="top-center"
                        closeButton={false}
                    />
                    <GlobalStyle />
                </AuthContextProvider>
            </Suspense>
        </ThemeProvider>
    </BrowserRouter>
);
