import { ColorRing } from "react-loader-spinner";
import PageWrapper from "./PageWrapper";

export const Loader = () => {
    return (
        <PageWrapper>
            <ColorRing
                visible
                height="80"
                width="80"
                colors={["#70EDB9", "#74EEB9", "#74EEB9", "#74EEB9", "#70EDB9"]}
            />
            <span>Loading...</span>
        </PageWrapper>
    );
};
