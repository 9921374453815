import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RoutesNames } from "../../routes";
import { Button } from "../common/Button";
import { Input } from "../common/Input";
import { errorHandler } from "../../helpers/handlers";
import LoginService from "../../services/login-service";
import { toast } from "react-toastify";
import { EMAIL_REGEX } from "../../helpers/validations";

function CheckEmailForm() {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<{ email: string }>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            email: ""
        }
    });

    const onSubmit: SubmitHandler<{ email: string }> = async (payload) => {
        try {
            const { data } = await LoginService.verifyEmail(payload.email);
            toast.success(data.message);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Input
                label="Email"
                error={errors.email?.message}
                type="email"
                placeholder="Email"
                id="email"
                {...register("email", {
                    required: "Email is required",
                    pattern: {
                        value: EMAIL_REGEX,
                        message: "Please enter a valid email address"
                    },
                    validate: {
                        spacesOnly: (value) => !!value.trim() || "Required field"
                    }
                })}
            />

            <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Checking..." : "Reset Password"}
            </Button>
            <Link to={RoutesNames.LOGIN}>Back to Login</Link>
        </Form>
    );
}

export default CheckEmailForm;

const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    a {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: var(--font-size-medium);
        line-height: 100%;
        text-align: center;
        color: var(--font-color-black);
        opacity: 0.5;
        margin: 10px 0;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
`;
