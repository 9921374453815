import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios";
import { TokenLocalHandler } from "../helpers/handlers";

const API_URL = `${process.env.REACT_APP_API_URL}`;

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (!config.headers) {
        config.headers = {} as AxiosRequestHeaders;
    }
    config.headers["Authorization"] = `Bearer ${TokenLocalHandler.get()}`;
    return config;
});

export default instance;
