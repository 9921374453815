import type { RegistrationFormInputs, UserResponse } from "../types";
import instance from "../api/instance";
import { HttpRoutes } from "../api/routes";
import type { AxiosResponse } from "axios";
import { TokenLocalHandler } from "../helpers/handlers";

export default class RegistrationService {
    static async registration(payload: RegistrationFormInputs): Promise<UserResponse["client"]> {
        try {
            const { data } = await instance.post<UserResponse>(HttpRoutes.REGISTER, payload);
            TokenLocalHandler.set(data.access_token);
            return data.client;
        } catch (error) {
            throw error;
        }
    }

    static async getVerificationCode(type: string): Promise<AxiosResponse<{ message: string }>> {
        return instance.post(HttpRoutes.VERIFY_CREDENTIAL, { type });
    }

    static async checkVerificationCode(
        type: string,
        code: string
    ): Promise<AxiosResponse<{ message: string }>> {
        return instance.post(HttpRoutes.VERIFY_CODE, { type, code });
    }
}
