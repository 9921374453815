import { ReactNode } from "react";
import styled from "styled-components";
import AnimatedLogo from "./AnimatedLogo";

interface Props {
    children: ReactNode;
    title?: string;
}

function PageWrapper({ children, title }: Props) {
    return (
        <Container>
            <AnimatedLogo />
            {title && <span className="section-title">{title}</span>}
            {children}
        </Container>
    );
}

export default PageWrapper;

const Container = styled.div`
    min-width: 424px;
    background: #fff;
    border-radius: 32px;
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        color: #000000;
        margin: 20px 0;
    }

    @media (max-width: 530px) {
        min-width: auto;
        margin: 8px;
        padding: 15px;
    }
`;
