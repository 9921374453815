import styled from "styled-components";

export const Label = styled.span`
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-small);
    line-height: 130%;
    text-align: center;
    color: var(--font-color-black);
    opacity: 0.5;
    margin-bottom: 10px;
`;

export const Description = styled.span`
    max-width: 420px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--font-size-small);
    line-height: 130%;
    color: var(--font-color-black);
    opacity: 0.5;
    margin-bottom: 20px;
    width: 100%;
`;
