import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const errorHandler = (err: any) => {
    console.error("Error:", err);

    if (err.response.data.message) {
        toast.error(err.response.data.message);
    } else if (err instanceof AxiosError) {
        toast.error(err.message);
    } else {
        toast.error("Error: An error occurred");
    }
};

export class TokenLocalHandler {
    static set(token: string) {
        localStorage.setItem("access_token", token);
    }

    static get(): string | null {
        return localStorage.getItem("access_token");
    }
}
