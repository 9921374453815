import { useEffect, useState } from "react";

export const useCountdown = () => {
    const [count, setCount] = useState<number>(45);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return { count, setCount };
};
