import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";

import { RoutesNames } from "../../routes";
import type { VerificationFormInputs } from "../../types";
import { Button } from "../common/Button";
import Checkbox from "../common/Checkbox";
import { Input } from "../common/Input";
import UploadInput from "../common/UploadInput";
import { errorHandler } from "../../helpers/handlers";
import VerificationService from "../../services/verification-service";

function VerificationForm() {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting }
    } = useForm<VerificationFormInputs>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            surname: "",
            city: "",
            name: "",
            zip_code: "",
            country: "",
            address: "",
            id_image: null,
            selfie_with_id: null,
            address_confirmation: null,
            agreement: false
        }
    });

    const onSubmit: SubmitHandler<VerificationFormInputs> = async (input) => {
        if (!input.agreement) {
            toast.error("Please read the Terms of Service and Privacy Policy");
            return;
        }

        try {
            const { data } = await VerificationService.verifyUser(input);
            const status = data.client.verification_status;
            status && navigate(`${RoutesNames.VERIFICATION}?status=${status}`);
        } catch (error) {
            errorHandler(error);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FlexContainer>
                <Input
                    label="Surname*"
                    error={errors.surname?.message}
                    type="text"
                    placeholder="Surname"
                    id="surname"
                    {...register("surname", {
                        required: "Surname is required",
                        pattern: {
                            value: /^[a-zA-Z]+$/,
                            message: "Invalid surname"
                        },
                        validate: {
                            spacesOnly: (value) => !!value.trim() || "Required field"
                        }
                    })}
                />
                <Input
                    label="City*"
                    error={errors.city?.message}
                    type="text"
                    placeholder="City"
                    id="city"
                    {...register("city", {
                        required: "City is required",
                        pattern: {
                            value: /^[a-zA-Z]+$/,
                            message: "Incorrect city"
                        },
                        validate: {
                            spacesOnly: (value) => !!value.trim() || "Required field"
                        }
                    })}
                />
            </FlexContainer>
            <FlexContainer>
                <Input
                    label="Name*"
                    error={errors.name?.message}
                    type="text"
                    placeholder="Name"
                    id="name"
                    {...register("name", {
                        required: "Name is required",
                        pattern: {
                            value: /^[a-zA-Z]+$/,
                            message: "Invalid name"
                        },
                        validate: {
                            spacesOnly: (value) => !!value.trim() || "Required field"
                        }
                    })}
                />
                <Input
                    label="Zip code*"
                    error={errors.zip_code?.message}
                    type="text"
                    placeholder="Zip code"
                    id="zip_code"
                    {...register("zip_code", {
                        required: "Zip code is required",
                        pattern: {
                            value: /^[a-zA-Z0-9]{4,10}$/,
                            message: "Invalid zip code"
                        },
                        validate: {
                            spacesOnly: (value) => !!value.trim() || "Required field"
                        }
                    })}
                />
            </FlexContainer>
            <FlexContainer>
                <Input
                    label="Country*"
                    error={errors.country?.message}
                    type="text"
                    placeholder="Country"
                    id="country"
                    {...register("country", {
                        required: "Country is required",
                        pattern: {
                            value: /^[a-zA-Z]+$/,
                            message: "Incorrect country"
                        },
                        validate: {
                            spacesOnly: (value) => !!value.trim() || "Required field"
                        }
                    })}
                />
                <Input
                    label="Address*"
                    error={errors.address?.message}
                    type="text"
                    placeholder="Address"
                    id="address"
                    {...register("address", {
                        required: "Address is required",
                        pattern: {
                            value: /^[A-Za-z0-9\s,./-]+$/,
                            message: "Incorrect address"
                        },
                        validate: {
                            spacesOnly: (value) => !!value.trim() || "Required field"
                        }
                    })}
                />
            </FlexContainer>
            <FlexContainer>
                <UploadInput
                    label="ID image*"
                    formats={[".png", ".jpg"]}
                    accept="image/*"
                    id="id_image"
                    name="id_image"
                    control={control}
                />
                <UploadInput
                    label="Selfie with ID*"
                    formats={[".png", ".jpg"]}
                    accept="image/*"
                    id="selfie_with_id"
                    name="selfie_with_id"
                    control={control}
                />
                <UploadInput
                    label="Address confirmation*"
                    formats={[".png", ".jpg"]}
                    accept="image/*"
                    id="address_confirmation"
                    name="address_confirmation"
                    control={control}
                />
            </FlexContainer>
            <Checkbox
                name="agreement"
                control={control}
                label={
                    <span>
                        By creating an account, I agree to Falconic's 
                        <a href="/">Terms of Service</a> and <a href="/">Privacy Policy</a>.
                    </span>
                }
            />
            <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Next"}
            </Button>
        </Form>
    );
}

export default VerificationForm;

const Form = styled.form`
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 530px) {
        width: 100%;
    }
`;

const FlexContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 22px;

    & > * {
        flex: 1;
    }

    @media (max-width: 530px) {
        flex-direction: column;
        gap: 10px;
        align-items: normal;
    }
`;
